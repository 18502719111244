@import 'styles/responsive';
@import 'styles/colors';

#match .games {
  margin-top: 1em;

  @media screen and (max-width: $mobile) {
    margin-top: 0.5em;
  }

  > .tabs {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    position: relative;

    &::after {
      content: ' ';
      width: 100%;
      position: absolute;
      border-bottom: 1px solid $black;
      bottom: 0;
    }

    .tabs-wrapper {
      display: flex;
      border: 1px solid $black;
      border-bottom: 0;
      border-radius: 5px 5px 0 0;
    }

    a {
      z-index: 3;
      border-bottom: 0;
      text-decoration: none;

      .tab {
        display: flex;
        padding: 0.5em 2em 0.4em 2em;
        border-bottom: 1px solid $black;
        font-family: 'Droid Serif', serif;
        font-variant: small-caps;
        font-weight: 700;

        .prefix {
          margin-right: 5px;
        }

        @media screen and (max-width: $mobile) {
          padding: 0.5em 1.5em 0.4em 1.5em;
          .prefix {
            display: none;
          }
        }
      }

      &:hover .tab {
        background-color: $hover-background;
      }

      &.active .tab {
        border-bottom-color: $background-color;
      }

      & + a {
        border-left: 1px solid $black;
      }
    }
  }
}
