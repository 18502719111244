@import 'styles/responsive';

#game .events .event {
  &.kill,
  &.first-blood {
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .grid {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-auto-flow: dense;

        .kill-icon {
          display: flex;
          align-items: center;
          padding: 0 1em;
          svg {
            width: 40px;
            height: 40px;
            transform: scaleX(-1);
            path {
              fill: #444;
            }
          }
        }

        > .dead {
          display: flex;
          justify-content: flex-end;
          margin: 5px 0;
        }

        .killers {
          display: flex;
          align-items: flex-end;
          margin-bottom: 5px;

          .assists {
            display: flex;
            //flex-wrap: wrap;

            .assist {
              margin-left: 0.5em;
            }
          }
        }

        .dead-name {
          text-align: right;
        }

        .killer-name {
          grid-column: 3;
          font-weight: 700;
        }
      }
    }

    &.blue {
      .grid {
        .dead {
          grid-column: 3;
          justify-content: flex-start;
        }

        .kill-icon {
          grid-column: 2;
          svg {
            transform: scaleX(1);
          }
        }

        .killers {
          grid-column: 1;
          flex-direction: row-reverse;

          .assists {
            .assist {
              margin-left: 0;
              margin-right: 0.5em;
            }
          }
        }

        .dead-name {
          grid-column: 3;
          text-align: left;
        }

        .killer-name {
          grid-column: 1;
          text-align: right;
        }
      }
    }

    @media screen and (max-width: $mobile) {
      .content .grid {
        grid-template-columns: auto auto auto;

        .kill-icon {
          padding: 0 0.5em;

          svg {
            width: 30px;
            height: 30px;
          }
        }

        .champion-portrait {
          --size: 45px !important;
        }

        .assists {
          max-width: 100px;
          flex-wrap: wrap;

          .assist {
            margin-top: 5px;
            .champion-portrait {
              --size: 20px !important;
            }
          }
        }

        .dead-name,
        .killer-name {
          font-size: 12px;
        }
      }
    }
  }
}
