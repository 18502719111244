@import 'styles/responsive';
@import 'styles/colors';

#page {
  max-width: 100vw;
  width: 800px;
  margin: auto;
  padding: 0 1em;

  @media screen and (min-width: $tablet) {
    border-left: 1px solid $black;
    border-right: 1px solid $black;
  }

  h1 {
    text-align: center;
    margin: 0;
    padding: 1em 0;
  }

  details {
    padding: 0.5em 0.5em 0;
    margin-bottom: 0.5em;
  }

  summary {
    font-weight: bold;
    margin: -0.5em -0.5em 0;
    padding: 0.5em;
    cursor: pointer;
  }

  details p {
    margin: 0 1em;
  }

  details[open] {
    padding: 0.5em;
  }

  details[open] summary {
    margin-bottom: 0.5em;
  }
}
