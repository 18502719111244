@import 'styles/colors';

.gold-graph {
  display: flex;
  justify-content: center;

  svg {
    fill: none;
    stroke: $black;
    display: block;
    stroke-width: 1px;

    text {
      font-family: Arial, Helvetica, sans-serif;
      fill: $black;
      font-size: 11px;
      font-weight: 100;
      stroke-width: 0;
    }

    .horizontal.tick.full {
      opacity: 0.15;
    }

    .positive {
      fill: $blue;
      stroke: $blue;
    }

    .negative {
      fill: $red;
      stroke: $red;
    }

    .line {
      fill: none;
    }

    .inner {
      cursor: pointer;
      fill: transparent;
    }

    .area {
      stroke: none;
      opacity: 0.3;
    }

    .slider {
      opacity: 0.5;
      stroke-dasharray: 2, 2;

      &.info {
        opacity: 1;
        font-weight: bold;
      }
    }

    .icon {
      stroke-width: 0;
    }

    .pointer {
      stroke-width: 1;
      stroke-dasharray: 2, 2;
      &.blue {
        stroke: $blue;
      }

      &.red {
        stroke: $red;
      }
    }
  }
}
