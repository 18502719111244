@import 'styles/responsive';
@import 'styles/colors';

#game .frame {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 96vw;
  width: 800px;
  margin: auto;

  padding-top: 0.5em;

  .top-bar {
    .timer {
      font-size: 22px;
      font-weight: 900;
      font-family: monospace;
      padding: 0.5em 0;

      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;

      .clock {
        display: grid;
        grid-template-columns: 1fr auto 1fr;

        span:first-of-type {
          text-align: right;
        }
      }

      .state {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 3px;

        $state-red: red;
        $state-grey: grey;

        &.in_game {
          background-color: $state-red;
          animation: ripple-red 1.2s linear infinite;
        }

        &.paused {
          background-color: $state-grey;
          animation: ripple-grey 1.2s linear infinite;
          animation-play-state: paused;
        }

        @keyframes ripple-red {
          0% {
            box-shadow: 0 0 0 0 rgba($state-red, 0.3), 0 0 0 0.5em rgba($state-red, 0.3),
              0 0 0 1.5em rgba($state-red, 0.3);
          }
          100% {
            box-shadow: 0 0 0 0.5em rgba($state-red, 0.3), 0 0 0 1.5em rgba($state-red, 0.3),
              0 0 0 2.5em rgba($state-red, 0);
          }
        }

        @keyframes ripple-grey {
          0% {
            box-shadow: 0 0 0 0 rgba($state-grey, 0.3), 0 0 0 0.5em rgba($state-grey, 0.3),
              0 0 0 1.5em rgba($state-grey, 0.3);
          }
          100% {
            box-shadow: 0 0 0 0.5em rgba($state-grey, 0.3), 0 0 0 1.5em rgba($state-grey, 0.3),
              0 0 0 2.5em rgba($state-grey, 0);
          }
        }
      }
    }
  }

  .score-line {
    padding: 0.5em 0;
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    width: 100%;

    .name {
      display: flex;
      align-items: center;

      font-size: 18px;
      font-weight: 700;

      justify-content: center;

      &.blue {
        color: $blue;
      }

      &.red {
        color: $red;
      }

      .code {
        display: none;
      }

      @media screen and (max-width: $mobile) {
        .full {
          display: none;
        }

        .code {
          display: initial;
        }
      }
    }

    .score {
      display: grid;
      grid-template-columns: 3fr 1fr auto 1fr 3fr;
      align-items: center;

      font-size: 20px;
      font-weight: 700;

      .blue {
        text-align: right;
      }

      .drakes {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-width: 64px;

        &.red {
          padding-left: 0.5em;
        }

        &.blue {
          flex-direction: row-reverse;
          padding-right: 0.5em;
        }
      }

      .separator {
        padding: 0 0.3em;
        display: flex;
      }
    }
  }

  .gold {
    padding: 0.5em 0;
    width: 100%;

    .bar {
      display: flex;
      width: 100%;

      .blue {
        flex: 1;
        height: 4px;
        background-color: $blue;
        margin-right: 2px;
      }

      .red {
        flex: 1;
        height: 4px;
        background-color: $red;
        margin-left: 2px;
      }
    }

    .values {
      padding-top: 0.5em;
      display: grid;
      grid-template-columns: 1fr auto 1fr;

      .blue,
      .red {
        font-weight: 700;
      }

      .red {
        margin-left: auto;
      }
    }
  }

  .teams-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    padding: 0.5em 0;

    .team-stats {
      display: flex;
      justify-content: space-evenly;

      &.blue-team {
        flex-direction: row-reverse;

        .icon .shape {
          fill: #4c7184;
        }
      }

      &.red-team {
        border-left: 1px solid #2f2f2f66;
        .icon .shape {
          fill: #844c4c;
        }
      }

      & > div {
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-content: center;

        & > .value {
          text-align: center;
          padding: 0.2em 0;
        }
      }
    }
  }

  .info-tabs {
    width: 100%;
    border-top: 1px solid #2f2f2f66;
    border-bottom: 1px solid #2f2f2f66;
  }

  .info-tab-players {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    .team-players {
      .player-stats {
        display: flex;
        flex-direction: column;
        padding: 0.5em 0;

        .first-line {
          padding-bottom: 0.5em;
          font-weight: 700;
        }

        .second-line {
          display: flex;

          .stats {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            direction: rtl;
            flex: 1;

            & > span {
              padding: 0 0.5em;
              display: flex;
              align-items: center;
              justify-content: center;

              & > span {
                direction: ltr;
              }
            }

            svg {
              margin-left: 2px;
            }

            .kills {
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }

          .items {
            display: flex;
            flex: 1;
            align-items: center;
            justify-items: center;
            justify-content: center;
            padding: 0 10px;

            .main-items {
              display: flex;
            }

            .item {
              width: 37px;
              height: 37px;
              border: 1px solid $black;
              border-radius: 6px;
              margin: 0.5px;

              &.empty {
                box-shadow: inset 0 0 10px #00000033;
              }
            }
          }
        }

        @media screen and (max-width: $mobile) {
          font-size: 0.8em;
          .champion-portrait {
            --size: 40px !important;
          }

          .stats {
            grid-template-columns: 1fr 1fr !important;

            & > span {
              padding: 0 !important;
              justify-content: flex-end !important;
            }

            .kills {
              grid-column: span 2;
              justify-content: center !important;
            }
          }
        }

        @media screen and (max-width: $mini) {
          .items {
            padding: 0 5px !important;
            .item {
              --size: 21px !important;
              width: 23px !important;
              height: 23px !important;
            }

            .main-items {
              display: grid !important;
              grid-template: 1fr 1fr / 1fr 1fr 1fr;
            }
          }
        }

        & + .player-stats {
          border-top: 1px solid #2f2f2f66;
        }
      }

      &.red-team {
        border-left: 1px solid #2f2f2f66;

        .first-line {
          text-align: right;
        }

        .second-line {
          flex-direction: row-reverse;

          .stats {
            flex-direction: row;
            margin-left: 0;
            margin-right: auto;
            direction: ltr;
          }

          .items {
            flex-direction: row-reverse;

            .main-items {
              flex-direction: row-reverse;
            }
          }
        }
      }
    }
  }

  .tabs-toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      background: transparent;

      padding: 0em 1em 0.2em 1em;
      border: 1px solid #2f2f2f66;
      border-top: 0;
      cursor: pointer;
      box-shadow: 0px 2px 2px #2f2f2f33;
      border-radius: 0 0 5px 5px;

      padding-left: 1.3em;

      &:hover {
        box-shadow: 0px 3px 3px #2f2f2f33;
        background-color: $hover-background;
      }
    }

    .active {
      button {
        background: $hover-background;
      }
    }
  }
}
