@import 'styles/colors';

.frame .timers {
  border-top: 1px solid #2f2f2f66;
  padding: 0.5em 0;
  width: 100%;

  display: flex;
  //align-items: center;
  justify-content: space-evenly;

  & > div {
    flex-basis: 200px;
    border: 2px solid #3339;
    padding: 0 10px;

    .right {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 0.2em 0;

      & > span:first-of-type {
        font-size: 0.8em;
        font-style: oblique;
      }
    }

    .clock {
      font-family: monospace;
      font-size: 1.3em;
    }

    &.blue {
      border-color: $blue;
      background-color: $background-blue;

      svg path {
        fill: $blue;
      }
    }

    &.red {
      border-color: $red;
      background-color: $background-red;

      svg path {
        fill: $red;
      }
    }
  }

  .baron-timer {
    display: flex;

    &.empty {
      display: none;
    }

    svg {
      width: 40px;
      height: 40px;
      margin: 5px;
    }
  }

  .dragon-timer {
    display: flex;

    svg {
      width: 40px;
      height: 40px;
      margin: 5px;
    }
  }

  .baron-timer:not(.empty) + .dragon-timer {
    @media screen and (max-width: 418px) {
      border-left: 0px;
    }
  }
}
