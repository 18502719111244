@import 'styles/responsive';
@import 'styles/colors';

#game .events {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 100vw;
  width: 800px;
  margin: auto;

  padding-top: 0.5em;

  .event {
    width: 100%;

    display: grid;
    grid-template-columns: 65px 1fr 65px;
    align-items: center;

    &.blue {
      background-color: $background-blue;
    }

    &.red {
      background-color: $background-red;
    }

    &.text {
      text-align: justify;
      & > .content {
        margin-left: 1em;
        grid-column: 1 / span 3 !important;
      }
    }

    &.winner {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h4 {
        margin: 0;
        padding-bottom: 0.5em;
      }
      img {
        max-width: 100px;
        max-height: 100px;
      }
    }

    &.dragon {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .announcement {
          .dragon-name {
            text-transform: capitalize;
            margin-right: 0.3em;
          }
        }

        svg {
          width: 60px;
          height: 60px;
        }
      }
    }

    &.tower,
    &.inhibitor {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          width: 40px;
          height: 40px;
          path {
            fill: #444;
          }
        }
      }
    }

    &.baron {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          width: 50px;
          height: 50px;
          path {
            fill: #444;
          }
        }
      }
    }

    &.gold-threshold {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          width: 30px;
          height: 30px;
          path {
            fill: #444;
          }
        }
      }
    }

    &.ace {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .teams {
          display: flex;

          .players {
            display: flex;

            .champion-portrait {
              position: relative;
              margin: 0 0.2em;

              &:before {
                content: '';
                width: 60px;
                height: 2px;
                background-color: black;
                position: absolute;
                transform: rotate(-45deg);
                opacity: 1;
                top: 17px;
                left: -8px;
                z-index: 1;
              }
              &:after {
                content: '';
                width: 60px;
                height: 2px;
                background-color: black;
                position: absolute;
                transform: rotate(45deg);
                opacity: 1;
                top: 21px;
                left: -8px;
              }
            }
          }

          svg {
            width: 30px;
            height: 30px;
            path {
              fill: #444;
            }
          }
        }
      }
    }

    &.at-15 {
      b {
        padding: 0 4px;
      }
    }

    .timestamp {
      text-align: center;
    }

    .content {
      margin: 0.8em 0;
      flex: 1;

      &.center {
        text-align: center;
      }

      .announcement {
        font-weight: 700;
        padding-bottom: 0.5em;
      }

      .subtitle {
        padding-top: 0.2em;
      }

      &.with-border {
        border: 1px solid $black;
        padding: 1em;
        margin-right: 1em;
        grid-column: 2 / span 2;
      }

      &.with-double-border {
        border: double $black;
        padding: 0.5em 1em;
        margin-right: 1em;
        grid-column: 2 / span 2;
      }
    }

    @media screen and (max-width: $desktop) {
      grid-template-columns: 50px 1fr;
    }
  }
}
