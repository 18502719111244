$mini: 680px;
$mobile: 768px;
$tablet: 769px;
$desktop: 1024px;
$widescreen: 1216px;
$fullhd: 1408px;

.is-widescreen {
  @media screen and (max-width: $widescreen) {
    display: none;
  }
}
