.item-image {
  --size: 35px;

  width: var(--size);
  height: var(--size);
  overflow: hidden;

  img {
    width: var(--size);
    height: var(--size);
    border-radius: 5px;
    filter: brightness(1.1);
  }
}
