@import 'styles/responsive';
@import 'styles/colors';

header {
  z-index: 10;

  .subtitle {
    border-top: 2px solid #2f2f2f;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-size: 14px;
    border-bottom: 2px solid #2f2f2f;
    padding: 4px 0px;

    .container {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;

      .subtitle-sentence {
        text-align: center;
      }

      .settings {
        display: flex;
        justify-content: flex-end;
        padding-right: 4px;
      }

      @media screen and (max-width: $mini) {
        grid-template-columns: 1fr 1fr;
        .subtitle-sentence {
          display: none;
        }
      }
    }
  }

  &.is-full {
    .title-wrapper {
      display: grid;
      grid-template-columns: 1fr auto 1fr;

      .title {
        grid-column-start: 2;

        margin: 0;
        font-family: 'Chomsky', serif;
        font-weight: 700;
        font-size: 60px;
        text-align: center;
        padding: 0.2em 0;
      }

      .infobox {
        margin: auto 30px auto auto;

        max-width: 300px;
        border: 3px double #2f2f2f;
        padding: 10px 15px 10px 15px;
        line-height: 20px;
        display: inline-block;
        text-align: justify;

        p {
          margin: 0;
          font-size: 14px;
        }

        a {
          color: black;
        }
      }

      @media screen and (max-width: $widescreen) {
        grid-template-columns: 1fr;

        .title {
          grid-column-start: 1;
        }

        .infobox {
          display: none;
        }
      }
    }

    @media screen and (max-width: $mobile) {
      .title-wrapper .title {
        font-size: 35px;
      }

      .subtitle {
        font-size: 12px;
      }
    }
    @media screen and (min-width: $tablet) {
      padding: 0 10px;

      .title-wrapper .title {
        font-size: 40px;
      }
    }
    @media screen and (min-width: $desktop) {
      .title-wrapper .title {
        font-size: 45px;
      }

      .subtitle {
        font-size: 13px;
      }
    }
    @media screen and (min-width: $widescreen) {
      .title-wrapper .title {
        font-size: 55px;
      }
    }
    @media screen and (min-width: $fullhd) {
      .title-wrapper .title {
        font-size: 65px;
      }

      .subtitle {
        font-size: 14px;
      }
    }
  }

  &.is-slim {
    position: sticky;
    top: 0;
    background-color: $background-color;

    a {
      text-decoration: none;
    }

    .go-home {
      a {
        padding: 5px 10px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .title-wrapper {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: center;

      border-bottom: 2px solid #2f2f2f;

      .title {
        font-family: 'Chomsky', serif;
        font-weight: 700;
        font-size: 30px;
        text-align: center;
        margin: 0;
      }

      .settings {
        margin-left: auto;
      }
    }

    @media screen and (max-width: $mobile) {
      .title-wrapper .title {
        font-size: 20px;
      }
    }
    @media screen and (min-width: $tablet) {
      padding: 0 10px;

      .title-wrapper .title {
        font-size: 22px;
      }
    }
    @media screen and (min-width: $desktop) {
      .title-wrapper .title {
        font-size: 24px;
        transition: font-size 0.5s ease-out;
      }
    }
    @media screen and (min-width: $widescreen) {
      .title-wrapper .title {
        font-size: 27px;
      }
    }
    @media screen and (min-width: $fullhd) {
      .title-wrapper .title {
        font-size: 30px;
      }
    }
  }
}
