@import 'styles/responsive';
@import 'styles/colors';

#home {
  section {
    @media screen and (min-width: $tablet) {
      border-left: 1px solid $black;
      border-right: 1px solid $black;
    }

    & + section {
      border-top: 1px solid $black;
    }

    &.content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .title {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;

    &.schedule img {
      transform: rotate(12deg);
    }

    &.live img:nth-of-type(2) {
      margin: 0 auto 0 1em;
      transform: scaleX(1);
    }

    img {
      max-width: 60px;
      max-height: 60px;
      width: auto;
      height: auto;
      transform: scaleX(-1);
      margin: 0 1em 0 auto;

      @media screen and (max-width: $mobile) {
        max-width: 40px;
        max-height: 40px;
        margin: 0 0.3em 0 auto;
      }
    }

    h2 {
      display: inline-flex;
      margin: 0;
    }
  }

  .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .tab-button {
      width: 100%;
      padding: 1em 0;
      text-decoration: none;
    }

    &:not(.is-active) {
      .tab-button + .tab-button {
        border-left: 1px solid $black;
      }
    }

    &.is-active {
      .tab-button {
        &:hover,
        &.active {
          background-color: $hover-background;
          &:nth-of-type(1) {
            border-radius: 0 10px 0 0;
            border-right: 1px solid $black;
            margin-left: 1px;
          }

          &:nth-of-type(2) {
            border-radius: 10px 0 0 0;
            border-left: 1px solid $black;
          }
        }

        .title {
          grid-template-columns: auto auto;
        }
      }
    }

    .title {
      img {
        max-width: 50px;
        max-height: 50px;
      }
      h2 {
        font-size: 25px;
      }

      @media screen and (max-width: $mobile) {
        img {
          max-width: 30px;
          max-height: 30px;
        }

        h2 {
          font-size: 18px;
        }
      }
    }
  }

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;

    &.is-tabs-active {
      grid-template-columns: 1fr;

      .column:not(.active) {
        display: none;
      }

      .column + .column {
        border-left: 0;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;

      & + .column {
        border-left: 1px solid $black;
      }
    }
  }
}
