@import 'styles/colors';

#game {
  font-family: sans-serif;
}

.no-data {
  max-width: 100vw;
  width: 800px;
  margin: 2em auto;
  padding: 1em;

  border: double $black;

  h3 {
    text-align: center;
  }

  ul li {
    padding: 0.2em 0;
  }
}
