//$background-color: #f9f7f1;
$background-color: #f8ede4;
$black: #2f2f2f;
$hover-background: #2f2f2f11;

$blue: #1580b6;
$red: #de2f2f;

/*$background-blue: rgba(21, 128, 182, 0.133);
$background-red: rgba(222, 47, 47, 0.086);*/

$background-blue: rgba(21, 128, 182, 0.17);
$background-red: rgba(222, 47, 47, 0.13);
