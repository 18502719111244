@import 'styles/colors';

.articles {
  display: flex;
  flex-direction: column;

  .featured {
    display: flex;
    overflow: hidden;
    position: relative;
    justify-content: space-around;

    article {
      flex: 1 1 250px;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      min-width: 200px;
      max-width: 250px;
      border: 1px solid $black;
      margin: 0 0.25em;

      a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        height: 100%;

        &:hover p {
          text-decoration: underline;
        }

        .img-wrapper {
          width: 100%;
          height: 110px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          img {
            max-width: 100%;
            min-height: 100%;
          }
        }

        .infos {
          padding: 13px;
          display: flex;
          flex-direction: column;
          flex: 1;

          .source {
            font-size: 12px;
            opacity: 0.7;
          }

          .news-title {
            margin: 0.3em 0 0.6em 0;
            padding: 0;
            font-size: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            flex: 1;
          }

          .timestamp {
            font-size: 12px;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;

    article {
      width: 700px;
      max-width: 100%;
      margin: 0.3em;
      border: 1px solid $black;

      a {
        padding: 0.3em;
        text-decoration: none;
        display: flex;

        &:hover p {
          text-decoration: underline;
        }

        .infos {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 0.3em 0.5em;
          min-width: 0;

          .news-title {
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            font-size: 15px;
          }

          .bottom {
            display: flex;
            justify-content: space-between;
            font-size: 12px;

            .source {
              opacity: 0.7;
            }

            .timestamp {
              opacity: 0.6;
            }
          }

          @media screen and (max-width: 550px) {
            margin: 0.1em 0.2em;

            .news-title {
              font-size: 13px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              white-space: initial;
            }

            .bottom {
              font-size: 10px;
            }
          }
        }

        .img-wrapper {
          width: 120px;
          height: 60px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          min-width: 120px;

          img {
            max-width: 100%;
            min-height: 100%;
          }
        }
      }
    }
  }
}
