@import './colors.scss';

@media (hover: hover) {
  [data-tooltip] {
    position: relative;

    &::after {
      content: attr(data-tooltip);
      font-size: 0.8em;
      display: none;
      background-color: $background-color;
      color: $black;
      justify-content: center;
      border: double $black;
      padding: 3px 13px 1px 13px;
      min-width: 40px;
      position: absolute;
      z-index: 10;
    }

    &::before {
      content: '';
      display: none;
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $background-color;
      border: double $black;
    }

    &:hover::before,
    &:hover::after {
      display: inline-flex;
      white-space: nowrap;
    }

    $tooltip-offset: 10px;
    $arrow-offset: 4px;

    &[data-tooltip-direction='top'],
    &:not([data-tooltip-direction]) {
      &::after {
        top: -$tooltip-offset;
        left: 50%;
        transform: translateY(-100%) translateX(-50%);
      }

      &::before {
        top: -$tooltip-offset;
        left: 50%;
        transform: translateY(-100%) translateY($arrow-offset) translateX(-50%) rotate(45deg);
      }
    }

    &[data-tooltip-direction='left'] {
      &::after {
        left: -$tooltip-offset;
        top: 50%;
        transform: translateX(-100%) translateY(-50%);
      }

      &::before {
        left: -$tooltip-offset;
        top: 50%;
        transform: translateX(-100%) translateX($arrow-offset) translateY(-50%) rotate(45deg);
      }
    }

    &[data-tooltip-direction='bottom'] {
      &::after {
        bottom: -$tooltip-offset;
        left: 50%;
        transform: translateY(100%) translateX(-50%);
      }

      &::before {
        bottom: -$tooltip-offset;
        left: 50%;
        transform: translateY(100%) translateY(-$arrow-offset) translateX(-50%) rotate(45deg);
      }
    }

    &[data-tooltip-direction='right'] {
      &::after {
        right: -$tooltip-offset;
        top: 50%;
        transform: translateX(100%) translateY(-50%);
      }

      &::before {
        right: -$tooltip-offset;
        top: 50%;
        transform: translateX(100%) translateX(-$arrow-offset) translateY(-50%) rotate(45deg);
      }
    }
  }
}
