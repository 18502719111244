@import './responsive.scss';
@import './tooltip.scss';
@import './colors.scss';

@font-face {
  font-family: Chomsky;
  src: url('/Chomsky.woff') format('woff'), url('/Chomsky.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100vh;

  font-family: 'Droid Serif', serif;
  color: $black;
  background-color: $background-color;
}

#root {
  min-height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }

  #root {
    min-height: -webkit-fill-available;
  }
}

h2 {
  //font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 30px;
  //font-style: italic;
  margin: 0 0.5em 0.5em 0.5em;

  @media screen and (max-width: $mobile) {
    font-size: 20px;
  }
}

a {
  color: $black;
}

picture {
  display: flex;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

main {
  padding-bottom: 60px;
  min-height: calc(100vh - 122px);
  display: flex;
  flex-direction: column;
}

main,
.container {
  max-width: 1024px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: $mobile) {
    padding-left: 0;
    padding-right: 0;
  }
}

// ---

.divider {
  position: relative;
  margin: 2em 0;
  height: 1px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(48, 49, 51), transparent);
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -6px;
    left: calc(50% - 7px);
    width: 14px;
    height: 12px;
    background-color: $background-color;
    border-left: 1px solid rgb(48, 49, 51);
    border-right: 1px solid rgb(48, 49, 51);
  }
}
