@import 'styles/responsive';

section.live {
  $border-color: #70645b;
  padding: 6px;

  .loading-wrapper {
    height: 120px;
    margin-top: 5em;
  }

  .outer-border {
    border: 4px solid $border-color;
    padding: 6px;

    .inner-border {
      $inner-border-width: 2px;
      border: $inner-border-width solid $border-color;
      position: relative;

      padding: 4em 0;

      img.corner {
        position: absolute;
        width: 50px;

        &:nth-of-type(1) {
          top: -$inner-border-width;
          left: -$inner-border-width;
        }

        &:nth-of-type(2) {
          top: -$inner-border-width;
          right: -$inner-border-width;
          transform: rotate(90deg);
        }

        &:nth-of-type(3) {
          bottom: -$inner-border-width;
          right: -$inner-border-width;
          transform: rotate(180deg);
        }

        &:nth-of-type(4) {
          bottom: -$inner-border-width;
          left: -$inner-border-width;
          transform: rotate(-90deg);
        }
      }

      img.vertical {
        position: absolute;
        width: 200px;
        filter: brightness(0.4); //grayscale(1);

        &:nth-of-type(5) {
          top: 2px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }

        &:nth-of-type(6) {
          bottom: 2px;
          left: 0;
          right: 0;
          margin: 0 auto;
          transform: scaleY(-1);
        }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    padding: 6px 0 0 0;

    .outer-border .inner-border {
      padding: 2em 0;
      img.corner {
        width: 35px;
      }
      img.vertical {
        width: 150px;
      }
    }
  }

  .no-live {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;

    img.lady {
      width: 200px;
      height: 250px;
      //filter: grayscale(0.5);
      z-index: 2;
    }

    blockquote {
      //font-family: 'Playfair Display', serif;
      font-size: 1.5em;
      //font-style: italic;
      padding-bottom: 4em;

      &:before {
        color: #c9a73e;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
        opacity: 0.8;
      }

      &:after {
        color: #c9a73e;
        content: close-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-left: 0.1em;
        vertical-align: -0.4em;
        opacity: 0.8;
      }
    }

    /*&:after {
      position: absolute;
      bottom: 0;
      content: '';
      width: 40%;
      border-bottom: 1px solid #2f2f2f;
    }*/

    @media screen and (max-width: $mobile) {
      flex-direction: column-reverse;
      margin-top: 0em;

      blockquote {
        font-size: 1.1em;
        margin: 0.5em;
        padding-bottom: 1em;

        &:before {
          font-size: 3em;
          margin-right: 0.1em;
        }

        &:after {
          font-size: 3em;
          margin-left: 0;
        }
      }

      img.lady {
        width: 100px;
        height: 125px;
      }
    }
  }
}
