@import 'styles/colors';

.app-filter {
  display: inline-flex;
  align-items: center;

  font-variant: small-caps;
  background: transparent;
  font-size: 14px;
  font-weight: bold;

  padding: 0em 1.2em 0.2em 1.1em;
  border: 1px solid #2f2f2faa;
  cursor: pointer;

  &:hover {
    background-color: $hover-background;
  }

  svg {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-top: 2px;
  }

  .count {
    margin-left: 5px;
    margin-bottom: -1px;
    font-size: 9px;
    font-weight: initial;
  }
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-hover {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000033;
  }

  .modal {
    position: relative;
    z-index: 2;
    max-width: 90vw;
    width: 420px;
    max-height: 90vh;
    height: 550px;
    background-color: $background-color;
    display: flex;
    flex-direction: column;

    border: double $black;

    h1 {
      margin: 0.1em 0.3em 0.5em 0.3em;
      border-bottom: 1px solid $black;
      text-align: center;
      //font-family: 'Playfair Display', serif;
      font-variant: small-caps;

      button {
        position: absolute;
        font-size: 16px;
        top: 0;
        right: 0;
        background: transparent;
        padding: 5px 5px 5px 7px;
        border: 0;
        margin: 0;
        cursor: pointer;
      }
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      font-family: 'Droid Serif', serif;
      font-weight: normal;
      font-size: 1.2em;
      flex: 1;
      overflow-y: auto;

      .leagues {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        & + .leagues {
          margin-top: 5px;
          padding-top: 5px;
          border-top: 1px solid $black;
          content: '';
          width: 100%;
        }

        &:last-of-type {
          margin-bottom: 10px;
        }

        .league {
          padding: 8px 10px;
          min-height: 56px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:not(.disabled):hover {
            cursor: pointer;
            background-color: $hover-background;
          }

          /*&:not(.checked):not(:hover) {
            .left img {
              opacity: 0.5;
            }

            .code {
              color: grey;
            }
          }*/

          &.disabled {
            .left img {
              opacity: 0.7;
            }

            .code {
              color: grey;
            }
          }

          .left {
            display: flex;
            align-items: center;
            
            picture {
              justify-content: center;
              width: 35px;
            }

            img {
              max-width: 35px;
              max-height: 40px;
              width: auto;
              height: auto;
              transition: opacity 0.1s ease-out;
            }

            .code {
              margin-left: 0.7em;
              font-weight: 700;
            }

            .region {
              margin-left: 0.5em;
              font-size: 0.8em;
              color: grey;
            }
          }

          .check {
            position: relative;
            border: 1px solid $black;
            width: 15px;
            height: 15px;

            input {
              opacity: 0;
              cursor: pointer;
            }

            svg {
              display: none;
              position: absolute;
              left: 0px;
              top: -4px;
              width: 18px;
              height: 18px;
            }

            input:checked + svg {
              display: initial;
            }
          }
        }
      }
    }
  }
}
