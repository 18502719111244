@import 'styles/colors';

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 10px;
  height: 60px;
  background-color: $background-color;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-top: 1px solid #2f2f2f;
    padding-bottom: 5px;
    font-style: italic;
  }

  .copyright {
    margin: 5px;
    font-size: 14px;
  }

  .not-affiliated {
    margin: 0;
    font-size: 12px;
    text-align: justify;
    max-width: 90vw;
  }
}
