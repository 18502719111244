@import 'styles/responsive';
@import 'styles/colors';

#match {
  @media screen and (min-width: $desktop) {
    border-left: 1px solid $black;
    border-right: 1px solid $black;
  }

  .meta {
    .meta-header {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      padding: 1em 0;
      align-items: center;

      .meta-title {
        grid-column-start: 2;
        font-size: 25px;
        font-weight: 700;
        //font-family: 'Playfair Display', serif;
      }

      .stream {
        margin-left: auto;

        a {
          padding: 0.5em;
          text-decoration: none;
          display: inline-flex;

          &:hover {
            background-color: $hover-background;
            border-radius: 5px;
          }

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      @media screen and (max-width: $mobile) {
        padding: 0.5em 0;
        display: flex;
        flex-wrap: wrap;

        .meta-title {
          grid-column-start: 1;
          font-size: 20px;
          text-align: center;
          margin: auto;
        }

        .stream {
          margin-top: 0.5em;
        }
      }
    }

    .teams-wrapper {
      display: flex;
      justify-content: center;
    }

    .teams {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-rows: auto auto;
      align-items: center;

      img {
        max-width: 150px;
        max-height: 150px;
        width: auto;
        height: auto;
        margin: auto;
      }

      .name {
        max-width: 200px;
        text-align: center;
        margin: 1em auto;
        font-weight: 700;
        font-family: 'Droid serif', serif;
        font-size: 1.5em;
        position: relative;
      }

      .vs {
        font-size: 2em;
        font-family: 'Droid serif', serif;
        font-weight: 900;
      }

      .vs,
      .state {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 50px;
      }

      &.has-winner {
        img:not(.winner) {
          opacity: 0.7;
        }

        .name {
          &:not(.winner) {
            opacity: 0.7;
          }

          &.winner::after {
            position: absolute;
            bottom: -7px;
            left: 20%;

            content: '';
            width: 60%;
            border-bottom: double #2f2f2f66;
          }
        }
      }

      @media screen and (max-width: $mobile) {
        flex: 1;

        img {
          max-width: 80px;
          max-height: 80px;
        }

        .vs,
        .state {
          margin: 0 10px;
        }

        .vs {
          font-size: 1.2em;
        }

        .state {
          font-size: 0.7em;
        }

        .name {
          font-size: 1em;
        }
      }
    }
  }
}
