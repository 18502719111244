.champion-portrait {
  --size: 50px;

  width: var(--size);
  height: var(--size);
  overflow: hidden;

  img {
    width: var(--size);
    height: var(--size);
    transform: scale3d(1.1, 1.1, 1.1);
    filter: brightness(1.2);
  }

  &.dead {
    img {
      filter: grayscale(1);
      opacity: 0.7;
    }
  }
}
